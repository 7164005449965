import React from "react"
import styled from "styled-components"
import Heading from "../../typography/Heading"
import { Body } from "../../typography"
import Button from "../../../uiKit/Button"
import { media } from "../../../constants/breakpoints"
import { spacing } from "../../../constants/grid"

const StyledWrapper = styled.div`
  grid-column-start: outer-xx-left;
  grid-column-end: outer-xx-right;
  display: grid;
  justify-items: center;
  margin-bottom: ${spacing.medium};
  height: 100%;
  grid-template-rows: max-content max-content max-content;
  grid-gap: 1em;

  @media ${media.medium} {
    grid-column-start: outer-x-left;
    grid-column-end: outer-x-right;
  }
  @media ${media.large} {
    grid-column-start: inner-left;
    grid-column-end: inner-right;
  }
`

const LgSignupTeaser = ({ content }) => {
  const { primary } = content
  return (
    <StyledWrapper>
      <Heading size="h3" align="center">
        {primary.header}
      </Heading>

      <Body align="center">{primary.paragraph}</Body>

      <Button
        type="a"
        url={primary.button_link?.path}
        theme={content.theme}
        style={process.env.THEME}
      >
        {primary.button_label}
      </Button>
    </StyledWrapper>
  )
}
export default LgSignupTeaser
